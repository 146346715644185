.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1306px;
    margin: 66px auto;

    &_midle{
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 527px;
    }
}

.img-screen{
    width: 765px;
    height: 527px;
    margin: auto 0;
}

.footer{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1306px;
    margin: 0 auto;

    &_title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size:20px;
    }

    &_sub-title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #0057B8;
        font-size:15px;
    }
}

.case{
    display: flex;
    position: relative;
    left: -2px;
    width: 441px;
    height: 862px;
    flex-direction: column;
    justify-content: space-between;
    top: -211px;
    

    &>span:first-child{
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #ffffff;
        font-size:45px;
    }

    &>span:last-child{
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size:45px;
        text-transform: uppercase;
        text-align: end;
        margin-left: auto;
    }
}



// @media(max-width:1124px){
//     .img-screen{
//         width: 565px;
//     }
//     .case{
        
//         &>span:first-child{
//             top: -156px;
//             font-size: 35px; 
//         }
    
//         &>span:last-child{
//             font-size: 35px;   
//             bottom: -68px;
//         }
//     }
// }

// @media(max-width:1024px){
//     .img-screen{
//         width: 465px;
        
//     }
// }

// @media(max-width:1024px){
//     .img-screen{
//         width: 465px;
//     }
// }